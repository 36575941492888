import HttpService from "./HttpService";

export default class PickupDropoff {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get pickup
   *
   * @param {*} id
   * @returns {Promise}
   */
  getPickup(id = null) {
    return this.httpService.get("/v1/pickups/" + id);
  }

  /**
   * Get orders dhl
   *
   * @param filters
   * @returns {Promise}
   */
  getOrdersDhl(filters = {}) {
    return this.httpService.get("/v1/pickups", {
      params: filters,
    });
  }

  /**
   * Set pickup
   *
   * @param data
   * @returns {Promise}
   */
  setPickup(data = {}) {
    return this.httpService.post("/v1/pickups/", data);
  }

  /**
   * Delete pickup
   *
   * @param {*} ids
   * @returns {Promise}
   */
  deletePickup(ids) {
    return this.httpService.delete("/v1/destroy-pickups/" + ids);
  }

  /**
   * Update pickup
   *
   * @param {*} id
   * @returns {Promise}
   */
  UpdatePickup(id, data) {
    return this.httpService.put("/v1/update-type/" + id, data);
  }
}
