<template>
  <div>
    <LoadingPage v-show="loaded" />
    <div v-show="!loaded">
      <section>
        <NbPageTitle :title="$t('pickupPage.title')" />
        <NbTabs
          class="mb-3"
          :tabs="tabsOptions"
          @switchTab="switchTab($event)"
        />
        <NbTablev2
          tableOf="pickupsTable"
          namespace="pickups"
          ref="pickupsTable"
          :allFields="allFields"
          :fields="fields"
          :selectable="true"
          :showBtnOptions="true"
          :buttonOptions="pickupOrderOptions"
          :optionsWidth="150"
          @optionTook="optionTook($event)"
          :selectedItems.sync="selectedOrders"
          @reloadFields="fields = $event"
          @total="totalItems = $event"
        >
          <template #cell(order_number)="data">
            <div class="link-1" @click="getOrderModal(data.item.id)">
              {{ data.item.order_number }}
            </div>
          </template>

          <template #cell(pickup_number)="data">
            <div v-if="data.item.pickup">
              {{ data.item.pickup.pickup_number }}
            </div>
          </template>

          <template #cell(pickup_id)="data">
            <div v-if="data.item.pickup">
              {{ data.item.pickup.id ? data.item.pickup.id : "-" }}
            </div>
          </template>
          <template #cell(remessa_type)="data">
            <div v-if="data.item.remessa_type === 'pu'">
              {{ $t(`pickupPage.${data.item.remessa_type}`) }}
            </div>
            <a
              v-else
              class="link-1"
              data-toggle="modal"
              data-target="#modalType"
              @click="selectedOrderId = data.item.id"
            >
              {{ $t(`pickupPage.${data.item.remessa_type}`) }}
            </a>
          </template>
          <template #cell(pickup_date)="data">
            <div v-if="data.item.pickup">
              {{ data.item.pickup.date ? data.item.pickup.date : "-" }}
            </div>
          </template>
        </NbTablev2>
      </section>
      <NbFooter
        :text="$t('components.footer.checkOurSupport')"
        link="https://nobordistinc.freshdesk.com/support/home"
      />
    </div>
    <!-- modals -->
    <ModalOrderView
      :orderId="currentOrderViewId"
      @reloadOrders="reloadOrders"
    />
    <NbModal
      id="modalCreatePickup"
      modalConfig="modal-dialog-centered"
      :preventClose="true"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">{{ $t("pickupPage.informDate") }}</div>
      </template>
      <template v-slot:body>
        <NbTextInput
          id="pickupInfoDate"
          type="date"
          :required="true"
          :name="$t('date')"
          :error="errors['data']"
          :min="getTomorrow()"
          v-model="pickupsInfo.date"
        />
      </template>
      <template v-slot:footer>
        <NbButton class="float-right mr-3" @click="createPickup()">
          {{ $t("save") }}
        </NbButton>
        <NbButton
          variant="secondary"
          class="float-right mr-2"
          data-dismiss="modal"
          aria-label="Close"
          >{{ $t("cancel") }}</NbButton
        >
      </template>
    </NbModal>

    <NbModal
      id="modalDeletePickup"
      modalConfig="modal-dialog-centered"
      :preventClose="true"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">{{ $t("pickupPage.sureDeletePickup") }}?</div>
      </template>
      <template v-slot:footer>
        <NbButton class="float-right mr-3" @click="deletePickup()">
          {{ $t("delete") }}
        </NbButton>
        <NbButton
          variant="secondary"
          class="float-right mr-2"
          data-dismiss="modal"
          aria-label="Close"
          >{{ $t("cancel") }}</NbButton
        >
      </template>
    </NbModal>
    <NbModal
      id="modalType"
      modalConfig="modal-dialog-centered"
      :preventClose="true"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">{{ $t("pickupPage.informType") }}</div>
      </template>
      <template v-slot:body>
        <NbSelectInput
          id="orderType"
          class="mb-3"
          :options="selectTypeOptions"
          v-model="selectedType"
        />
        <NbTextInput
          v-if="selectedType === 'pu'"
          id="pickupselectedDate"
          type="date"
          :required="true"
          :name="$t('date')"
          :min="getTomorrow()"
          v-model="selectedDate"
        />
      </template>
      <template v-slot:footer>
        <NbButton class="float-right mr-3" @click="updateType()">
          {{ $t("update") }}
        </NbButton>
        <NbButton
          variant="secondary"
          class="float-right mr-2"
          data-dismiss="modal"
          aria-label="Close"
          >{{ $t("cancel") }}</NbButton
        >
      </template>
    </NbModal>
    <!-- /modals -->
  </div>
</template>

<script>
import NProgress from "nprogress";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
//import NbCard from "@/components/cards/NbCard.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import ModalOrderView from "@/views/orders/components/ModalOrderView.vue";

import PickupDropoffService from "../../services/PickupDropoffService";

const pickupDropoffService = new PickupDropoffService();

export default {
  name: "OverpacksList",
  components: {
    LoadingPage,
    NbPageTitle,
    //NbCard,
    NbTabs,
    NbTablev2,
    NbFooter,
    NbModal,
    NbButton,
    NbTextInput,
    NbSelectInput,
    ModalOrderView,
  },
  data: () => {
    return {
      currentOrderViewId: "",
      loaded: true,
      totalItems: 0,
      pickupsInfo: {
        date: null,
      },
      errors: [],
      fields: [],
      selectedOrders: [],
      selectedOrderId: null,
      selectedType: null,
      selectedDate: null,
      currentTable: "all",
    };
  },
  created() {
    setTimeout(() => {
      this.loaded = false;
    }, 1000);
    this.fields = this.allFields;
  },
  methods: {
    getOrderModal(id) {
      if (id) {
        this.currentOrderViewId = id;
        this.$helpers.openModal("modal-order-view");
        return;
      }
    },
    reloadOrders() {
      this.$refs?.tableOrders?.getData();
    },
    selectedItems(event) {
      this.selectedOrders = event;
    },
    optionTook(event) {
      this.$helpers.openModal(event);
      //this[event]();
    },
    updateType() {
      let data = {
        remessa_type: this.selectedType,
        date: this.selectedDate,
      };
      pickupDropoffService
        .UpdatePickup(this.selectedOrderId, data)
        .then((response) => {
          this.$helpers.closeModal("modalType");
          if (response.data.status === "SUCCESS") {
            this.$helpers.toast(response.data.messages[0], "success");
            this.$refs.pickupsTable.getData();
          }
        })
        .catch(({ response }) => {
          if (response.data && response.data.messages) {
            this.$helpers.toast(response.data.messages[0], "danger");
          }
          NProgress.done();
        });
    },

    createPickup() {
      const pickupsToCreate = this.selectedOrders;
      this.errors = [];

      pickupDropoffService
        .setPickup({
          pickup_ids: pickupsToCreate,
          date: this.pickupsInfo.date,
        })
        .then((response) => {
          const message = response.data.messages[0];
          this.$helpers.toast(message, "success", 3000);
          this.$helpers.closeModal("modalCreatePickup");
          this.$refs.pickupsTable.getData();
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },

    deletePickup() {
      pickupDropoffService
        .deletePickup(this.selectedOrders.join(","))
        .then(() => {
          this.$helpers.closeModal("modalDeletePickup");
          this.$helpers.toast("Pickup(s) deleted", "success", 3000);
          this.$refs.pickupsTable.getData();
        })
        .catch((error) => {
          NProgress.done();
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },

    getTomorrow() {
      let today = new Date();
      today.setDate(new Date().getDate() + 1);
      return today.toJSON().slice(0, 10);
    },

    reloadFields(event) {
      this.fields = event;
    },
  },
  computed: {
    selectTypeOptions() {
      return [
        { text: this.$t("pickupPage.pu"), value: "pu" },
        { text: this.$t("pickupPage.do"), value: "do" },
        { text: this.$t("pickupPage.nothing"), value: "nothing" },
      ];
    },
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("totalItems"),
          found: this.totalItems,
          current: this.currentTable,
        },
      ];
    },
    pickupOrderOptions() {
      return [
        {
          text: this.$t("pickupPage.createPickup"),
          value: "modalCreatePickup",
        },
        {
          text: this.$t("pickupPage.deletePickup"),
          value: "modalDeletePickup",
        },
      ];
    },
    allFields() {
      return [
        { key: "id", label: this.$t("pickupPage.fields.orderId") },
        {
          key: "order_number",
          label: this.$t("pickupPage.fields.orderNo"),
          sortable: true,
        },
        { key: "remessa_type", label: this.$t("pickupPage.fields.type") },
        {
          key: "pickup_id",
          label: this.$t("pickupPage.fields.pickupID"),
          sortable: true,
        },
        {
          key: "pickup_number",
          label: this.$t("pickupPage.fields.pickupNumber"),
        },
        {
          key: "pickup_date",
          label: this.$t("pickupPage.fields.pickupData"),
          sortable: true,
        },
      ];
    },
  },
};
</script>
